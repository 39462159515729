<template>
    <v-card>
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>Edit investigation details</v-toolbar-title>

            <v-spacer />
            
            <v-btn @click="save()" small text>Save</v-btn>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-form
                    ref="form"
                >
                    <v-row>
                        <v-text-field
                            label="Date of Investigation"
                            v-model="investigationDateInput"
                            required
                            type="date"
                            placeholder="dd/mm/yyyy"
                            :max="currentDate"
                        ></v-text-field>
                    </v-row>

                    <v-row>
                        <v-switch
                            label="Reviewing the allegations and/or photos provided, does the apparatus belong to Virgin Media?"
                            v-model="apparatusVMLocal"
                            color="success"
                            inset
                        ></v-switch>
                    </v-row>

                    <v-row v-if="!apparatusVMLocal">
                        <v-textarea
                            label="Why, and who might it belong to?"
                            v-model="apparatusVMDetailsLocal"
                            :rules="rules.required"
                            placeholder="Please detail why you think this apparatus is not VM's and whose you think it might be"
                            hint="Please detail why you think this apparatus is not VM's and whose you think it might be"
                        ></v-textarea>
                    </v-row>

                    <v-row v-if="!apparatusVMLocal">
                        <v-alert type="info" dense>
                            Take Video / Image of why you think this is not VM and whose apparatus you think this might be.
                        </v-alert>
                    </v-row>

                    <v-row v-if="apparatusVMLocal">
                        <v-textarea
                            label="When was the apparatus installed?"
                            v-model="whenInstalledLocal"
                            :rules="rules.required"
                            placeholder="When was the apparatus installed? Was it over 2 years ago? (Explain free text)"
                            hint="When was the apparatus installed? Was it over 2 years ago? (Explain free text)"
                        ></v-textarea>
                    </v-row>

                    <v-row v-if="apparatusVMLocal">
                        <v-textarea
                            label="When was the apparatus last attended?"
                            v-model="lastAttendedLocal"
                            :rules="rules.required"
                            placeholder="When was the apparatus last attended? Are there any records which show how often the apparatus has been attended 12 months prior to the accident? (Explain free text)"
                            hint="When was the apparatus last attended? Are there any records which show how often the apparatus has been attended 12 months prior to the accident? (Explain free text)"
                        ></v-textarea>
                    </v-row>

                    <v-row v-if="apparatusVMLocal">
                        <v-switch
                            label="Is the apparatus visually defective?"
                            v-model="defectiveLocal"
                            color="success"
                            inset
                        ></v-switch>
                    </v-row>

                    <v-row v-if="apparatusVMLocal && defectiveLocal">
                        <v-textarea
                            label="Please detail why you think this is"
                            v-model="defectiveDetailsLocal"
                            :rules="rules.required"
                        ></v-textarea>
                    </v-row>

                    <v-row v-if="apparatusVMLocal && defectiveLocal">
                        <v-alert type="info" dense>
                            Take Video / Take Image of why you think this is.
                        </v-alert>
                    </v-row>

                    <v-row v-if="apparatusVMLocal && defectiveLocal">
                        <v-switch
                            label="Is the defect still present?"
                            v-model="defectPresentLocal"
                            color="success"
                            inset
                        ></v-switch>
                    </v-row>

                    <v-row v-if="apparatusVMLocal">
                        <v-switch
                            label="Does it look like any recent works by a third party may have been in the vicinity, that could have caused a defect to VM apparatus?"
                            v-model="thirdPartyRecentWorksLocal"
                            color="success"
                            inset
                        ></v-switch>
                    </v-row>

                    <v-row v-if="apparatusVMLocal">
                        <v-switch
                            label="Are there any other hazards in the immediate vicinity?"
                            v-model="hazardsVicinityLocal"
                            color="success"
                            inset
                        ></v-switch>
                    </v-row>

                    <v-row v-if="apparatusVMLocal && hazardsVicinityLocal">
                        <v-textarea
                            label="Please explain"
                            v-model="hazardsVicinityDetailsLocal"
                            :rules="rules.required"
                        ></v-textarea>
                    </v-row>

                    <v-row v-if="apparatusVMLocal && hazardsVicinityLocal">
                        <v-alert type="info" dense>
                            Take Video / Image of this.
                        </v-alert>
                    </v-row>

                    <v-row v-if="apparatusVMLocal">
                        <v-switch
                            label="Is there evidence of recent work by VM which may have caused the alleged incident?"
                            v-model="evidenceVMWorkLocal"
                            color="success"
                            inset
                        ></v-switch>
                    </v-row>

                    <v-row v-if="apparatusVMLocal && evidenceVMWorkLocal">
                        <v-textarea
                            label="Please detail why you think this is"
                            v-model="evidenceVMWorkDetailsLocal"
                            :rules="rules.required"
                        ></v-textarea>
                    </v-row>

                    <v-row v-if="apparatusVMLocal && evidenceVMWorkLocal">
                        <v-alert type="info" dense>
                            Take Video / Image of why you think this is.
                        </v-alert>
                    </v-row>

                    <v-row v-if="apparatusVMLocal && evidenceVMWorkLocal">
                        <v-textarea
                            label="Who is the contractor responsible for this work?"
                            v-model="contractorLocal"
                            :rules="rules.required"
                        ></v-textarea>
                    </v-row>

                    <v-row v-if="apparatusVMLocal">
                        <v-textarea
                            label="Any observations which may help decide liability?"
                            v-model="observationsLocal"
                            :rules="rules.required"
                            placeholder="Do you have any observations based on the facts above which may help decide liability?"
                            hint="Do you have any observations based on the facts above which may help decide liability?"
                        ></v-textarea>
                    </v-row>

                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import tasksAgent from "../../Tasks/tasksAgent.js";

export default {
    props: {
        id: String,
        date: String,                   // "DD/MM/YYYY"
        thirdPartyRecentWorks: Boolean,
        apparatusVM: Boolean,
        apparatusVMDetails: String,
        whenInstalled: String,
        lastAttended: String,
        defective: Boolean,
        defectiveDetails: String,
        defectPresent: Boolean,
        hazardsVicinity: Boolean,
        hazardsVicinityDetails: String,
        evidenceVMWork: Boolean,
        evidenceVMWorkDetails: String,
        contractor: String,
        observations: String,
    },
    
    data: function () {
        return {
            investigationDateLocal: this.date,
            investigationDateFormat: "DD/MM/YYYY",
            thirdPartyRecentWorksLocal: this.thirdPartyRecentWorks,
            apparatusVMLocal: this.apparatusVM,
            apparatusVMDetailsLocal: this.apparatusVMDetails,
            whenInstalledLocal: this.whenInstalled,
            lastAttendedLocal: this.lastAttended,
            defectiveLocal: this.defective,
            defectiveDetailsLocal: this.defectiveDetails,
            defectPresentLocal: this.defectPresent,
            hazardsVicinityLocal: this.hazardsVicinity,
            hazardsVicinityDetailsLocal: this.hazardsVicinityDetails,
            evidenceVMWorkLocal: this.evidenceVMWork,
            evidenceVMWorkDetailsLocal: this.evidenceVMWorkDetails,
            contractorLocal: this.contractor,
            observationsLocal: this.observations,

            currentDate: this.$moment().format('YYYY-MM-DD'),

            rules: {
                required: [v => !!v || "Required"],
            }
        };
    },
    
    computed: {
        investigationDateInput: {
            get: function() {
                return this.investigationDateLocal
                    ? this.$moment(this.investigationDateLocal, this.investigationDateFormat).format("YYYY-MM-DD")
                    : null;
            },
            set: function(newValue) {
                if (newValue)
                    this.investigationDateLocal = this.$moment(newValue).format(this.investigationDateFormat);
            }
        }
    },

    mounted: function () {
        this.refreshValidation();
    },

    methods: {

        refreshValidation () {
            this.$nextTick(() => {
                this.$refs.form.validate();
            });
        },

        save () {
            var l = this.$coreUi.loading();
            
            // Load workItem
            tasksAgent.getTask(this.id).then(result => {

                // Set fields and save
                result.aspectData.formBuilder.formData["dateInvestigation"] = this.investigationDateLocal;
                result.aspectData.formBuilder.formData["toggleThirdPartyRecentWorks"] = this.thirdPartyRecentWorksLocal;
                result.aspectData.formBuilder.formData["toggleApparatusVM"] = this.apparatusVMLocal;
                result.aspectData.formBuilder.formData["memoApparatusVM"] = this.apparatusVMLocal ? null : this.apparatusVMDetailsLocal;
                result.aspectData.formBuilder.formData["memoWhenInstalled"] = this.apparatusVMLocal ? this.whenInstalledLocal : null;
                result.aspectData.formBuilder.formData["memoLastAttended"] = this.apparatusVMLocal ? this.lastAttendedLocal : null;
                result.aspectData.formBuilder.formData["toggleDefective"] = this.apparatusVMLocal ? this.defectiveLocal : null;
                result.aspectData.formBuilder.formData["memoDefective"] = this.apparatusVMLocal && this.defectiveLocal ? this.defectiveDetailsLocal : null;
                result.aspectData.formBuilder.formData["toggleDefectPresent"] = this.apparatusVMLocal && this.defectiveLocal ? this.defectPresentLocal : null;
                result.aspectData.formBuilder.formData["toggleHazardsVicinity"] = this.apparatusVMLocal ? this.hazardsVicinityLocal : null;
                result.aspectData.formBuilder.formData["memoHazardsVicinity"] = this.apparatusVMLocal && this.hazardsVicinityLocal ? this.hazardsVicinityDetailsLocal : null;
                result.aspectData.formBuilder.formData["toggleEvidenceVMWork"] = this.apparatusVMLocal ? this.evidenceVMWorkLocal : null;
                result.aspectData.formBuilder.formData["memoEvidenceVMWork"] = this.apparatusVMLocal && this.evidenceVMWorkLocal ? this.evidenceVMWorkDetailsLocal : null;
                result.aspectData.formBuilder.formData["memoContractor"] = this.apparatusVMLocal && this.evidenceVMWorkLocal ? this.contractorLocal : null;
                result.aspectData.formBuilder.formData["memoObservations"] = this.apparatusVMLocal ? this.observationsLocal : null;

                // Don't invoke these aspect savers (they could fail)
                delete result.aspectData.participantPicker;
                delete result.aspectData.taskAssignedTo;
                delete result.aspectData.taskDetails;
                delete result.aspectData.taskDueDate;
                
                tasksAgent.saveTask(this.id, result).then(() => {

                    l.dismiss();
                    this.close(true);

                }).catch(console.error);

            }).catch(console.error);
        },

        close (result) {
            this.$emit("close", result);
        }
    },
};
</script>
